import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Creating animated hamburger menu icon",
  "description": "How to create a animated hamburger icon with css",
  "date": "2019-07-11",
  "image": "hamburger.jpg",
  "tags": ["css", "frontend"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In this blog, you will learn how to create an animated hamburger icon when being hovered over by using Pure CSS.`}</p>
    <h2>{`Demo`}</h2>
    <undefined><div {...{
        "style": {
          "textAlign": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "/7c2ee2bda4e293de99d1610051483a0c/hamburger-icon.gif",
          "alt": "demo"
        }}></img>
      </div>{`
## Implementation`}</undefined>
    <h3>{`Initial styling`}</h3>
    <p>{`Firstly, let's create the html`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`container`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`menu-icon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`line line-1`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`line line-2`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`line line-3`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Next is the css for initial styling.
To make things organized, scss is used to be able to declare some reusable variables.`}</p>
    <p>{`We will use flexbox to place the container in the middle of our screen.
Keep in mind that to make it work,
we need to set the height and weight of the body tag too since body tag is the container of our container div.
In this case, we could set it to 100% of height and width of the viewport.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$container-size`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 400px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$container-bg`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #3faf82`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$line-space`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 13px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$line-height`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 8px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$line-width`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 80px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`body `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` flex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`justify-content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`align-items`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100vw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100vh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.container `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px 2px 10px 0px `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rgba`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0.3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`darken`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$container-bg`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 15%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` flex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`justify-content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`align-items`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$container-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$container-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 8px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #fff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 3px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 2px 10px 0 `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rgba`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0.3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-1 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-space`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-3 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-space`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Animate the icon with `}<a href="https://www.w3schools.com/css/css3_transitions.asp" target="_blank">{`transition`}</a></h3>
    <p>{`What we want to achieve is to make the second line disappear,
the first and third lines move to the center and rotate 45deg and 135deg respectively. In this case,
we can use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition`}</code>{` to create the animation effect. This selector comes with a few properties in terms of effect, timing, delay and duration, which allows us
to create smooth effect.`}</p>
    <p>{`To begin with, let's make the second line shrink in and disappear.
We can achieve that by using scale and transform.
Also, let's make the transition time into a variable to make it easier to reuse later`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-time`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.2s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-2 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` all ease `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$transition-time`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scale`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.menu-icon:hover `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-2 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scale`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Here is what we got`}</p>
    <div {...{
      "style": {
        "textAlign": "center"
      }
    }}>{`
    `}<img parentName="div" {...{
        "src": "/2e7949013ff66bcfe67e06687c55c29c/line-2.gif",
        "alt": "line-2"
      }}></img>
    </div>
    <br />
    <p>{`Next, let's move to line-1 and line-3. In general, these two lines share the same behaviors but with small difference in styling.
They both need to have two actions which are moving to line-2's place and rotating at a certain degree.
We can use two css selectors to make it happen. The first is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`top/botttom`}</code>{` to shift them to center of the icon and
use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transform: rotate`}</code>{` to perform the later.`}</p>
    <p>{`The distance to move line-1 and line-3 to line-2 is equal the total of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`line-height`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`line-space`}</code>{`.
We can declare it into a variable called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`line-offsetY`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$line-offsetY`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-height`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-space`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.menu-icon:hover `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`.line-1 `}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`top`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$line-offsetY`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-2 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scale`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`.line-3 `}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`bottom`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$line-offsetY`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`For `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition`}</code>{` to work out for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`top/bottom`}</code>{`, we have to declare such properties in their un-hovered state as well.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-1 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`top`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-space`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` top `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$transition-time`}</span>{` ease`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-3 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`bottom`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-space`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bottom `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$transition-time`}</span>{` ease`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "style": {
        "textAlign": "center"
      }
    }}>{`
 `}<img parentName="div" {...{
        "src": "/3a7c0ac0e1b1f28d9eba10ff937ebf51/shift-position.gif",
        "alt": "shift-position"
      }}></img>
    </div>
    <p>{`Look promising! Now we can focus on making it rotate.
There is a tricky part here. The rotation happens after the shifting on the forwards transition, but it would happen before
the shifting on backward transition. This is known as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reversed transition`}</code>{`. Luckily, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition`}</code>{` allows us to create effect with specific selectors
and also have a property what allows us to declare the delay of the movement.
Hence, we need to declare `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition`}</code>{` in both un-hover and hover state with a suitable delay time. Enough with the writing, let's jump to the coding part`}</p>
    <p>{`We add the rotate for line-1 and line-3 with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transform: rotate`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.menu-icon:hover `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-1 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-offsetY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transform`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`rotate`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`45deg`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-2 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scale`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-3 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-offsetY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transform`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`rotate`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`135deg`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Next, we will add transition for both states of the lines.
A variable called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$transition-delay`}</code>{` is created for reusability`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-delay`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.5s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-1 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-space`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transition`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` top `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-time`}</span>{` ease `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-delay`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` transform`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-time`}</span>{` ease`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-3 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-space`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transition`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` bottom `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-time`}</span>{` ease `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-delay`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` transform`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-time`}</span>{` ease`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.menu-icon:hover `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-1 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-offsetY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rotate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`45deg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transition`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` top `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-time`}</span>{` ease`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` transform `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-time`}</span>{` ease`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-delay`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-2 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scale`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.line-3 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line-offsetY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rotate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`135deg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transition`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` bottom `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-time`}</span>{` ease`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` transform `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-time`}</span>{` ease`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$transition-delay`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The key here is to know where to put the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition-delay`}</code>{`.
Since we want the lines to rotate after the shift while the icon is being hovered, we need
to declare the delay for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transform`}</code>{` in the hover state. When we un-hover the icon, the rotation would go before the lines shift back to their initial position,
we would need to delay the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`top/bottom`}</code>{` in their initial state.`}</p>
    <p>{`This challenge has given me a hard time to figure it out that because I would typically
use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition`}</code>{` for the initial state and never think about it. It took me a few hours to have an aha moment but it was interesting to learn.
You can see the demo with the code `}<a href="https://codepen.io/Dragonza/pen/NZLzPB?editors=0100">{`here`}</a>{`.`}</p>
    <p>{`If you are not a fan of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition`}</code>{`, you can also use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`animation`}</code>{` selector to create the same result.`}</p>
    <p>{`This is a challenge I found from `}<a href="https://100dayscss.com/?dayIndex=1">{`100dayscsschallenge`}</a>{`. Give it a try if you want to level up your css skills.`}</p>
    <p>{`That's it! Thank you for taking your time to read the blog.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      